import "jquery";
import domReady from '@roots/sage/client/dom-ready';
import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import MicroModal from 'micromodal';
import 'is-in-viewport';
import './gpt.js'


function alertBanner() {
  var bannerHeight = $('.alert-banner').outerHeight();
  var shouldHide = sessionStorage.getItem('shouldHide');

  if (shouldHide) {
    $(".alert-banner").hide();
  }
  
  // $(".main-header").css("top", bannerHeight +"px");


  $(".alert-banner .close").click(function(){
    $(this).parents(".alert-banner").slideUp();
    sessionStorage.setItem('shouldHide', 'true');
  });

}

function isVisible() {
  viewportDetection();

  $( window ).scroll( function() {
    viewportDetection();
  });

  function viewportDetection() {
    $( '[data-viewport="detect"]:in-viewport' ).addClass( 'visible' );
    $( '[data-viewport="detect-delay"]:in-viewport(100)' ).addClass( 'visible' );
    

    }
  }
function swiper() {
  const swiper3 = new Swiper('.team-swiper', { 
    modules: [Navigation, Pagination],
    loop: true, 
    speed: 400, 
    slidesPerView: 3,
    grabCursor: true,
    spaceBetween: 16,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      }
    },
    navigation: {
      nextEl: '.swiper-nav .next', 
      prevEl: '.swiper-nav .prev',
    },
    pagination: {
      el: ".swiper-pagination",
    },
  });

  const swiper2 = new Swiper('.icon-swiper', { 
    
    modules: [Navigation, Pagination],
    loop: true, 
    speed: 400, 
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 0, 
    navigation: {
      nextEl: '.swiper-nav .next', 
      prevEl: '.swiper-nav .prev',
    },
    pagination: {
      el: ".swiper-pagination",
    },

    
  });

  const swiper4 = new Swiper('.history-swiper', { 
    
    modules: [Navigation, Pagination],
    loop: true, 
    speed: 400, 
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 0, 
    navigation: {
      nextEl: '.swiper-nav .next', 
      prevEl: '.swiper-nav .prev',
    },
    pagination: {
      el: ".swiper-nav .swiper-pagination",
      clickable: true,
    },

    
  });

  const swiper5 = new Swiper('.std-swiper', { 
    
    modules: [Navigation, Pagination],
    loop: true, 
    speed: 400, 
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 16, 
    navigation: {
      nextEl: '.swiper-nav .next', 
      prevEl: '.swiper-nav .prev',
    },
    pagination: {
      el: ".swiper-nav .swiper-pagination",
      clickable: true,
    },

    
  });

  const swiper6 = new Swiper('.products-swiper', { 
    
    modules: [Navigation, Pagination, Scrollbar],
    loop: false, 
    speed: 400, 
    slidesPerView: 1,
    grabCursor: true,
    spaceBetween: 16, 
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 3.5,
      },
    },

    
  });



}

function tabs() {
  $("#tab1, #panel1").addClass("active");
  $(".tab").click(function(){
    var tabId = $(this).attr("id");
    $(".tab").removeClass("active");
    $(this).addClass("active");
    $(".panel.active").fadeOut(200, function() {
        $(".panel.active").removeClass("active");
        $("#" + tabId.replace("tab", "panel")).addClass("active").fadeIn(200);
    });
});
}

function navigation() {
  var headerHeight = $('.main-header').outerHeight();
  
  $(".menu-toggle").click(function(){
    $(".main-navigation").toggleClass("active");
    $(".main-navigation").css("top", headerHeight);
    $('.main-header').toggleClass("active");
    $('.hamburger').toggleClass("is-active");
  });

  $(".mobile-menu li.menu-item-has-children a").click(function(){
  $(this).toggleClass("active");
  $(this).next(".sub-menu").slideToggle();
});
  

  $(".mega-menu-toggle a").mouseenter(function(e){
    e.preventDefault();
    
    
    $('#mega-menu').toggleClass("active");
    $('.main-header').addClass("mm-open")
  })
  $("#mega-menu .close").click(function(){
    $('#mega-menu').removeClass("active");
  });
  $("#mega-menu").mouseleave(function(){
    $('#mega-menu').removeClass("active");
  });
  $(document).keydown(function(event) {
    if (event.key === "Escape" || event.keyCode === 27) {
        $('#mega-menu').removeClass("active");
    }
});
}

function adjustMegaMenu() {
  var preHeight = $('.pre-nav').outerHeight();
  var headerHeight = $('.main-header').outerHeight();
  var totalHeight = preHeight + headerHeight;

  if ($('.main-header').hasClass('fixed')) {
      $('#mega-menu').css("top", headerHeight);
  } else {
      $('#mega-menu').css("top", totalHeight);
  }
}

function viewportDetection() {
  $( '[data-viewport="detect"]:in-viewport' ).addClass( 'visible' );
}

function steps() {
  $(".step:first-of-type").addClass("active")
  $(".step-title").click(function(){
    const $step = $(this).parent(".step");
    const $stepText = $step.find('.step-text');
    const $paragraph = $stepText.find('p');

    if ($step.hasClass('active')) {
      // Closing the step
      $step.removeClass('active');
      $stepText.css('max-height', '1rem');
    } else {
      // Opening the step
      $step.addClass('active');
      $stepText.css('max-height', $paragraph.outerHeight() + 'px');
    }
  });
}



function layout() {
  $('.footer-menu h5, .mega-menu h5').html(function(_, html) {
    return html.replace(/\b(\w+)$/, '<span>$1</span>');
  });

  $('.customers-number').each(function() {
    var text = $(this).text();
    var wrappedText = text.replace(/(\d)/g, '<span>$1</span>');
    $(this).html(wrappedText);
  });

  if ($(window).width() > 992) {
    var ctaHeight = $('.single-container-cta').outerHeight();
    $('#container-intro .swiper-slide img').css("height", ctaHeight+"px");
  }

}

function faqAccordian() {
  $('.faq-item').click(function(){
    $(this).toggleClass("active");
    $(this).children(".faq-item-a").slideToggle();
  })
}

function fixedHeader() {
  
    var scrollThreshold = 300;
    $(window).scroll(function() {
        var scrollPos = $(window).scrollTop();
        if (scrollPos > scrollThreshold) {
            $('.main-header').addClass('fixed');
        } else {
            $('.main-header').removeClass('fixed');
        }
    });
  }

  function overlayCTA() {
    var overlayHeight = $(".overlaid").outerHeight();
    
    if ($(window).width() > 960) {
      $(".overlaid").css({"margin-top": -overlayHeight/2,"margin-bottom": -overlayHeight/2-128});
      $(".overlaid").prev("section").css("padding-bottom", (overlayHeight/2));
      $(".overlaid").next("section").css("padding-top", (overlayHeight/2)+120);
    } else {
      $(".overlaid").css({"margin-top": -overlayHeight/2 ,"margin-bottom": -overlayHeight/2});
      $(".overlaid").prev("section").css("padding-bottom", (overlayHeight/2)+80);
      $(".overlaid").next("section").css("padding-top", (overlayHeight/2+40));
    }
    var blogOverlayHeight = $(".blog-overlaid").outerHeight();
      
    if ($(window).width() > 960) {
      $(".blog-overlaid").css({"margin-top": -blogOverlayHeight/2,"margin-bottom": -blogOverlayHeight/2});
          $(".blog-overlaid").prev("section").css("padding-bottom", (blogOverlayHeight/2)+80);
          $(".blog-overlaid").next("section").css("padding-top", (blogOverlayHeight/2)+80);
      }
  }

  function titleWrap() {
    $('.title-wrap').each(function() {
      var text = $(this).text();
      var words = text.trim().split(/\s+/);

      if (words.length === 2) {
          $(this).html(words[0] + ' <span>' + words[1] + '</span>');
      } else if (words.length === 3) {
          $(this).html(words[0] + ' ' + words[1] + ' <span>' + words[2] + '</span>');
      } else if (words.length === 4) {
          $(this).html(words[0] + ' ' + words[1] + ' <span>' + words[2] + ' ' + words[3] + '</span>');
      }
  });
  }

  function team() {
    $('.read-bio').click(function(e) {
      
      e.preventDefault();

      var memberId = $(this).data('id'); // Get the repeater index ID
      var loadingGif = '<img src="/path/to/loading.gif" alt="Loading..." class="loading-gif">'; // Replace with your GIF URL

      // Fade out existing content
      $('.bio-wrapper, .name').fadeOut(300, function() {
          // Show the loading GIF in the bio-wrapper
          $('.bio-wrapper').html(loadingGif).fadeIn(300);

          $.ajax({
              url: '/wp-admin/admin-ajax.php', // WordPress AJAX handler
              type: 'POST',
              data: {
                  action: 'get_team_member_bio',
                  member_id: memberId
              },
              success: function(response) {
                  if (response.success) {
                      // Fade out the loading GIF and update with new content
                      $('.bio-wrapper').fadeOut(300, function() {
                          $(this).html(response.data.bio).fadeIn(300);
                      });
                      
                      // Update the .name div with the name
                      $('.name').fadeOut(300, function() {
                          $(this).html(response.data.name).fadeIn(300);
                      });
                  } else {
                      $('.bio-wrapper').fadeOut(300, function() {
                          $(this).html('<p>Sorry, something went wrong. Please try again later.</p>').fadeIn(300);
                      });
                  }
              },
              error: function() {
                  $('.bio-wrapper').fadeOut(300, function() {
                      $(this).html('<p>Sorry, something went wrong. Please try again later.</p>').fadeIn(300);
                  });
              }
          });
      });
  });
  }


  function sectionStacker() {
  // Find all elements with the class 'global-padding'
  var $globalPaddingSections = $('.global-padding');
    
  // Iterate over each section
  $globalPaddingSections.each(function(index) {
      var $currentSection = $(this);
      
      // Check if the current section has a preceding sibling with the class 'global-padding'
      if ($currentSection.prev().hasClass('global-padding')) {
          // Remove the margin-top from the current section
          $currentSection.css('margin-top', '0');
          
          // Also, remove the margin-bottom from the previous section
          $currentSection.prev().css('margin-bottom', '0');
      }
  });
  
  }

  function mobileForm() {
    
     // Populate the mobile-form-toggle select with options from input_3_3
    var $inputSelect = $('#input_3_3');
    var $mobileFormToggle = $('.mobile-form-toggle');
    
    // Clear existing options in the mobile-form-toggle
    $mobileFormToggle.empty();

    // Add options from input_3_3 to mobile-form-toggle
    $inputSelect.find('option').each(function() {
        var optionValue = $(this).val();
        var optionText = $(this).text();
        $mobileFormToggle.append($('<option>', { value: optionValue, text: optionText }));
    });

    // Handle change event
    $mobileFormToggle.change(function() {
        var selectedValue = $(this).val();

        // Hide the -hide div and show the -content div
        $('.mobile-form-hide').hide();
        $('.mobile-form-content').show();

        // Set the selected value in the input_3_3 dropdown
        $inputSelect.val(selectedValue);
    });
  
  }
  function swapElements() {
    // if ($(window).width() < 992) {
    //   $('.single-container-cta--section-2').insertAfter('.icons');
    // } else {
    //   $('.icons').insertAfter('.single-container-cta--section-2');
    // }
    if ($(window).width() < 992) {
      $('.swap-me').detach().insertBefore('.swap-me-destination');
    }
  }

/**
 * Application entrypoint
 */
domReady(async () => {
  layout();
  steps();
  tabs();
  alertBanner();
  swiper();
  navigation();
  viewportDetection();
  faqAccordian();
  fixedHeader();
  overlayCTA();
  titleWrap();
  team();
  sectionStacker();
  mobileForm();
  adjustMegaMenu();
  swapElements();
  MicroModal.init();
  


    $('#field_3_1 input[type="radio"]').on('change', function() {
        $('.quick-quote-form-wrapper').addClass('active');
    });





});

$(window).on('resize scroll', function() {
  adjustMegaMenu();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
